@use '~bootstrap/scss/bootstrap';

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #00171f;
  color: #ffffff;
}

#main {
  width: 100%;
  height: 100%;
}

#main-nav {
  box-shadow: 0 2px 2px black;
  margin-bottom: 10px;
  background-color: #007ea7;
}

.sound-name {
  text-align: right;
}

.reward-map td {
  vertical-align: middle;
}

article.card {
  margin-bottom: 10px;
}

.missing-rewards-list .btn {
  margin-right: 10px;
}

.bg-dark,
.table-dark {
  background-color: #003459 !important;
}

.list-group-item-dark {
  background-color: #00243d;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.table-dark .table-row-stripe {
  background-color: rgba(255, 255, 255, 0.05);
}

.main-footer {
  padding: 5px 10px;
  text-align: center;
  color: #007ea7;
}

.usernav {
  img {
    height: 30px;
    border-radius: 15px;
  }
}
