.notfound-page {
  text-align: center;

  header {
    font-size: 120px;
    font-weight: 900;
  }
  p {
    font-size: 40px;
  }
}
